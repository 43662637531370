<template>
  <div class="join">正在进入....</div>
</template>

<script>
import { getTeacherLoginInfo } from '@/service/auth.js';
import { saveSessionStorage } from '@/utils/cache.js';
export default {
  name: 'Token',

  mounted() {
    if (this.$route.params.token) {
      saveSessionStorage('Token', this.$route.params.token);
      this.getTeacherLoginInfo();
    }
  },
  methods: {
    async getTeacherLoginInfo() {
      let res = await getTeacherLoginInfo();
      this.$store.commit('set_userInfo', res);
      this.$router.push({ path: '/home' });
    }
  }
}
</script>

<style>
.join {
  padding: 20px;
}
</style>